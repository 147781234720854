<template>
  <div class="knives__language">
    <div
      class="knives__language-title"
      :class="{ active: isShowDropdown }"
      @click="isShowDropdown = !isShowDropdown"
    >
      {{ $i18n.locale }} <img src="@/assets/img/knives/lang-arrow.svg" alt="" />
    </div>
    <ul v-if="isShowDropdown" class="knives__language-dropdown">
      <li
        class="knives__language-item"
        :class="{ active: $i18n.locale === 'ru' }"
        @click="toggleLanguage"
      >
        RU
      </li>
      <li
        class="knives__language-item"
        :class="{ active: $i18n.locale === 'kz' }"
        @click="toggleLanguage"
      >
        KZ
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesCity } from "@/store/modules/city";
export default {
  name: "KnivesLangSelect",
  computed: {
    ...mapState(["currentLanguage"]),
    ...mapState("catalogModule", { selectedFilters: "filters" }),
    ...mapState("cityModule", ["selectedCity"]),
  },
  data() {
    return {
      isShowDropdown: false,
    };
  },
  methods: {
    ...mapActions({ changeLang: actionTypes.changeCurrentLanguage }),
    ...mapActions("cityModule", {
      getAllCities: actionTypesCity.loadAllCities,
      saveChoosedCity: actionTypesCity.changeSelectedCity,
    }),
    toggleLanguage() {
      if (this.$i18n.locale === "ru") {
        this.isShowDropdown = false;
        this.changeLang("kz");
        this.$i18n.locale = "kz";
        this.getAllCities().then((cities) => {
          const qsCity = this.$route.query.city_id
            ? parseInt(this.$route.query.city_id)
            : JSON.parse(localStorage.getItem("selectedCity")) || 2;
          const city = cities.find((c) => c.id === qsCity);
          this.$router.onReady(() => {
            if (!this.$route.query.city_id) {
              this.$router.push({
                params: { lang: this.$i18n.locale || "ru" },
                query: { city_id: city?.id || city || 2 },
              });
            }
          });
          this.saveChoosedCity(city);
        });
        localStorage.setItem("lang", this.$i18n.locale);
        this.$router.push({
          params: { lang: this.$i18n.locale },
          query: this.$route.query,
        });

        return;
      }
      this.changeLang("ru");
      this.isShowDropdown = false;

      this.$i18n.locale = "ru";
      this.getAllCities().then((cities) => {
        const qsCity = this.$route.query.city_id
          ? parseInt(this.$route.query.city_id)
          : JSON.parse(localStorage.getItem("selectedCity")) || 2;
        const city = cities.find((c) => c.id === qsCity);
        this.$router.onReady(() => {
          if (!this.$route.query.city_id) {
            this.$router.push({
              params: { lang: this.$i18n.locale || "ru" },
              query: { city_id: city?.id || city || 2 },
            });
          }
        });
        this.saveChoosedCity(city);
      });
      localStorage.setItem("lang", this.$i18n.locale);
      this.$router.push({
        params: { lang: this.$i18n.locale },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
